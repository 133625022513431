/* eslint-disable no-param-reassign */
import { RefObject, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isUndefined } from 'lodash-es';
import { useToasts } from '@veo/web-design-system';
import { seekVideo } from 'src/components/veo-player/utils/player-actions';
import { CapturedFrame } from '../models/captured-frame';
import { MIN_TELESTRATION_TIMESTAMP_THRESHOLD, MAX_TELESTRATION_TIMESTAMP_THRESHOLD } from '../constants/telestration';
import { formatFloat, captureStrechedFrame } from '../utils/video';
import { useTimeOffset } from './useTimeOffset';

type Props = {
  videoRef: RefObject<HTMLVideoElement>;
  enabled?: boolean;
  width?: number;
};

export function useFrameCapture({ videoRef, enabled = false, width: frameWidth = 640 }: Props) {
  const { t } = useTranslation();
  const Toasts = useToasts();

  const [capturedFrame, setCapturedFrame] = useState<CapturedFrame | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const timeOffset = useTimeOffset({ videoRef });

  function adjustThreshold(): Promise<number> {
    return new Promise((resolve, reject) => {
      if (videoRef.current && !isUndefined(timeOffset)) {
        const { currentTime, duration } = videoRef.current;

        const min = MIN_TELESTRATION_TIMESTAMP_THRESHOLD;
        const max = formatFloat(duration - MAX_TELESTRATION_TIMESTAMP_THRESHOLD);

        const isOutsideRange = currentTime < min || currentTime > max;

        let adjustedTime = formatFloat(currentTime, 2);

        if (isOutsideRange) {
          adjustedTime = currentTime < min ? min : max;
        }

        seekVideo(videoRef, adjustedTime + timeOffset)
          .then(() => {
            resolve(adjustedTime);
          })
          .catch(() => reject(new Error('Failed to adjust currentTime')));
      } else {
        reject(new Error('Failed to adjust currentTime'));
      }
    });
  }

  async function captureFrameWithTime(time: number) {
    if (!videoRef.current || isUndefined(timeOffset)) {
      return null;
    }

    const { url, data } = await captureStrechedFrame(videoRef, frameWidth);

    const result = {
      url,
      data,
      source: {
        width: videoRef.current.videoWidth,
        height: videoRef.current.videoHeight,
      },
      canvas: { width: frameWidth, height: frameWidth },
      timestamp: time,
    };

    return result;
  }

  async function captureFrame() {
    if (videoRef.current && !isUndefined(timeOffset) && enabled) {
      setIsLoading(true);
      videoRef.current.pause();

      let res = null;

      try {
        const adjustedTime = await adjustThreshold();

        res = await captureFrameWithTime(adjustedTime);
      } catch (error) {
        Toasts.add({
          variant: 'error',
          description: t('telestration.frame_capture.default_capture_error'),
        });
      } finally {
        setIsLoading(false);
        setCapturedFrame(res);
      }
    }
  }

  function revokeFrame() {
    if (capturedFrame) {
      URL.revokeObjectURL(capturedFrame.url);
      setCapturedFrame(null);
    }
  }

  return {
    isLoading,
    data: capturedFrame,
    revokeFrame,
    captureFrame,
  };
}

import { forwardRef, ForwardedRef } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { Text } from '@veo/web-design-system';
import { useImage } from 'src/common/hooks/useImage';
import { Paper } from 'src/components/paper/paper';
import { IZolaClip } from 'src/common/model/interfaces/IZolaClip';
import { Privacy } from 'src/common/model/enums/Privacy';
import { formatDuration } from 'src/common/utils/luxon-utils';
import defaultThumbnail from 'src/styles/images/video_thumbnail.webp';
import { ClipStatusTag } from './clip-status-tag';
import styles from './clip-item.module.scss';

type Props = {
  clip: IZolaClip;
  onClick(clip: IZolaClip): void;
  active?: boolean;
};

function ClipItemCmp(
  { clip, active = false, onClick }: Props,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const { t } = useTranslation();

  const {
    duration: clipDuration,
    addedToPlayerClips,
    tags,
    isRendering,
    thumbnail,
    match,
  } = clip;

  const { imageSrc: thumbnailSrc } = useImage(thumbnail, defaultThumbnail);

  const isInPrivateMatch = match.privacy === Privacy.Private;
  const disabled = addedToPlayerClips || isInPrivateMatch || isRendering;

  function handleClipClicked() {
    if (!disabled) {
      onClick(clip);
    }
  }

  const duration = formatDuration({ seconds: clipDuration }, 'mm:ss');

  const clipItemClassnames = cn(
    styles.container,
    {
      [styles.active]: active,
      [styles.disabled]: disabled,
    },
  );

  return (
    <Paper
      role="button"
      tabIndex={0}
      elevation={active ? 3 : 0}
      ref={ref}
      aria-disabled={disabled}
      onClick={handleClipClicked}
      className={clipItemClassnames}
    >
      <img
        className={styles.thumbnail}
        src={thumbnailSrc}
        width={84}
        height={84}
        alt={match.title}
      />
      <Text
        type="div"
        className={styles.title}
      >
        { match.title }
      </Text>
      <div className={styles.tags}>
        {
          tags.map((tag) => (
            <Paper
              key={tag}
              elevation={active ? 2 : 3}
              className={styles.tag}
            >
              { tag }
            </Paper>
          ))
        }
      </div>
      <Text className={styles.duration}>
        { duration }
      </Text>
      {
        addedToPlayerClips && <ClipStatusTag status={t('add_player_clips.added_label')} />
      }
      {
        isInPrivateMatch && <ClipStatusTag status={t('add_player_clips.private_label')} />
      }
      {
        isRendering && <ClipStatusTag status={t('add_player_clips.rendering_label')} />
      }
    </Paper>
  );
}

export const ClipItem = forwardRef<HTMLDivElement, Props>(ClipItemCmp);

/* eslint-disable react/no-array-index-key */
import { ChangeEvent, useMemo } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { Text, RadioButton } from '@veo/web-design-system';
import { Nullable } from 'src/common/model/types/helper.type';
import {
  Position, PlayStyle, PlayStyleMap, PlaystylePlaceholder,
} from 'src/common/model/enums/Position';
import styles from './playstyle-picker.module.scss';

type Props = {
  position: Nullable<Position>;
  playstyle: Nullable<PlayStyle>;
  onChange(nextPlaystyle: PlayStyle): void;
};

export function PlayStylePicker({ position, playstyle: playerPlaystyle, onChange }: Props) {
  const { t } = useTranslation();

  const emptyPlaystyles = useMemo(() => new Array(4).fill(PlaystylePlaceholder), []);

  function handleChangePlaystyle(e: ChangeEvent<HTMLInputElement>) {
    onChange(e.target.value as PlayStyle);
  }

  const playstyles = position ? PlayStyleMap[position] : emptyPlaystyles;

  return (
    <div className={styles.container}>
      {
        playstyles.map((playstyle, index) => (
          <label
            key={`${playstyle}_${index}`}
            htmlFor={`${playstyle}_${index}`}
            className={styles.playstyle}
          >
            <div className={styles.radio}>
              <RadioButton
                name={`${playstyle}_${index}`}
                id={`${playstyle}_${index}`}
                value={playstyle}
                disabled={!position}
                onChange={handleChangePlaystyle}
                checked={playstyle === playerPlaystyle}
              />
            </div>
            <div className={cn(styles.title, { [styles.disabled]: !position })}>
              <Text
                size="h6"
                type="div"
              >
                {t(`entity.playstyle.${playstyle}.title`, { order: !position ? index + 1 : null })}
              </Text>
            </div>
            <div className={cn(styles.description, { [styles.disabled]: !position })}>
              <Text
                size="caption"
                weight="medium"
                type="div"
              >
                {t(`entity.playstyle.${playstyle}.description`)}
              </Text>
            </div>
          </label>
        ))
      }
    </div>
  );
}

import { useTranslation } from 'react-i18next';
import { Text, Button } from '@veo/web-design-system';
import { LinkWrapper } from 'src/components/link/link';
import { config } from 'src/config';
import { Nullable } from 'src/common/model/types/helper.type';
import { IMatch } from 'src/common/model/interfaces/IMatch';
import styles from './empty-clips-state.module.scss';

type Props = {
  match: Nullable<IMatch>
};

export function EmptyClipsState({ match }: Props) {
  const { t } = useTranslation();

  const title = match
    ? t('add_player_clips.empty_clips.match_filter_title')
    : t('add_player_clips.empty_clips.default_title');

  const description = match
    ? t('add_player_clips.empty_clips.match_filter_description')
    : t('add_player_clips.empty_clips.default_description');

  return (
    <div className={styles.container}>
      <Text
        size="h5"
        type="div"
        weight="medium"
      >
        { title }
      </Text>
      <Text
        size="caption"
        type="div"
      >
        { description }
      </Text>
      {
        !!match && (
          <LinkWrapper
            external
            link={`${config.ZOLA_URL}${match.url}`}
            target="_blank"
          >
            <Button
              variant="primary"
              iconEnd="arrow-up-right"
              label={t('add_player_clips.empty_clips.match_filter_action_label')}
            />
          </LinkWrapper>
        )
      }
    </div>
  );
}

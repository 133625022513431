import { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Avatar, Menu, MenuList, useMenu, MenuListItem, Divider, Text, Button,
} from '@veo/web-design-system';
import { config } from 'src/config';
import { useOidcAuth } from 'src/features/oidc';
import { getUserInitials } from 'src/common/utils/user';
import { LinkWrapper } from 'src/components/link/link';
import styles from './user-state-indicator.module.scss';

export function UserStateIndicator() {
  const [menuOpen, setMenuOpen] = useState(false);

  const { t } = useTranslation();

  const {
    setMenu, setButton, getMenuProps, getButtonProps,
  } = useMenu(
    menuOpen,
    setMenuOpen,
    'bottom',
  );

  const {
    data: user, signoutRedirect, signinRedirect,
  } = useOidcAuth();

  async function logout() {
    signoutRedirect();
  }

  const userData = useMemo(() => {
    if (!user) {
      return { label: '', avatar: undefined };
    }

    const { firstName, lastName, avatar: userAvatar } = user;

    const label = getUserInitials(firstName, lastName);
    const avatar = userAvatar ?? undefined;

    return { avatar, label };
  }, [user]);

  if (!user) {
    return (
      <Button
        label={t('auth.login_button_label')}
        size="sm"
        variant="outlined"
        iconStart="user"
        onClick={() => signinRedirect()}
      />
    );
  }

  return (
    <>
      <Avatar
        ref={setButton}
        {...getButtonProps()}
        size="sm"
        initials={userData.label}
        image={userData.avatar}
      />
      <Menu {...getMenuProps()} className={styles.menu} ref={setMenu}>
        <MenuList>
          <div className={styles['user-wrapper']}>
            <Avatar
              size="sm"
              initials={userData.label}
              image={userData.avatar}
            />
            <div className={styles['text-wrapper']}>
              <Text
                className={styles.name}
                type="div"
                size="h6"
                weight="semiBold"
              >
                { `${user.firstName} ${user.lastName}`}
              </Text>
              <Text
                className={styles.email}
                type="div"
                size="caption"
                weight="regular"
              >
                { user.email }
              </Text>
            </div>
          </div>
          <Divider />
          <LinkWrapper
            external
            link={`${config.ZOLA_URL}/accounts/settings`}
          >
            <MenuListItem
              title={t('layout.usermenu_settings_button_label')}
              iconStart="settings"
              onClick={() => setMenuOpen(false)}
            />
          </LinkWrapper>
          <Divider />
          <MenuListItem
            title={t('layout.usermenu_logout_button_label')}
            iconStart="log-out"
            onClick={logout}
          />
        </MenuList>
      </Menu>
    </>
  );
}

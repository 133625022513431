import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  includes, uniqueId, without, take, uniq, keyBy, uniqBy,
} from 'lodash-es';
import { Text, Button } from '@veo/web-design-system';
import { AbilityCode } from 'src/common/model/enums/AbilityCode';
import { Paper } from 'src/components/paper/paper';
import { IAbility } from 'src/common/model/interfaces/IAbility';
import { useAbilities } from 'src/common/hooks/abilities/useAbilities';
import { MEDIA_QUERY } from 'src/common/constants/media-query';
import { useMediaQuery } from 'src/common/hooks/useMediaQuery';
import { useAuthPlayer } from 'src/features/auth';
import { POSITION_ABILITY_MAP as abilityMap } from 'src/common/constants/position-ability-map';
import { Skeleton } from 'src/components/skeletons/skeleton';
import { AbilityItem } from 'src/components/ability';
import styles from './edit-abilities.module.scss';

type Props = {
  assigned: AbilityCode[];
  onChange(c: AbilityCode[]): void;
};

export function EditAbilities({ assigned, onChange }: Props) {
  const [collapsed, setCollapsed] = useState(true);
  const { data: staticAbilities, isLoading } = useAbilities();
  const { data: authPlayer } = useAuthPlayer();

  const isMobileLayout = useMediaQuery(MEDIA_QUERY.MOBILE);

  const { t } = useTranslation();

  const displayAbilities: IAbility[] = useMemo(() => {
    if (!staticAbilities?.length) {
      return [];
    }

    const collapsedItemCount = 6;

    const staticAbilitiesMap = keyBy(staticAbilities, 'code');
    const firstPriorityCodes = authPlayer?.position
      ? uniq([...assigned, ...abilityMap[authPlayer.position]])
      : [...assigned];

    const orderedAbilities = uniqBy(
      [...firstPriorityCodes.map((code) => staticAbilitiesMap[code]), ...staticAbilities],
      'code',
    );

    if (collapsed) {
      return take(orderedAbilities, collapsedItemCount);
    }

    return orderedAbilities;
  }, [collapsed, staticAbilities]);

  function handleChangeAbilities(ac: AbilityCode) {
    const exists = includes(assigned, ac);

    let result = [...assigned, ac];

    if (exists) {
      result = without(assigned, ac);
    }

    onChange(result);
  }

  function toggleExpand() {
    setCollapsed((prev) => !prev);
  }

  const expandButtonLabel = collapsed
    ? t('player_clip_details.abilities.show_more_button_label')
    : t('player_clip_details.abilities.show_less_button_label');

  return (
    <Paper
      elevation={isMobileLayout ? 2 : 3}
      className={styles.container}
    >
      <Text type="div" weight="medium">
        { t('player_clip_details.abilities.title') }
      </Text>
      <div className={styles.list}>
        {
          isLoading && (
            new Array(5).fill(0).map(() => <Skeleton key={uniqueId('ability')} />)
          )
        }
        {
          !isLoading && (
            displayAbilities?.map((a) => (
              <AbilityItem
                key={a.code}
                code={a.code}
                active={assigned.includes(a.code)}
                onClick={() => handleChangeAbilities(a.code)}
              />
            ))
          )
        }
        <Button
          variant="text"
          size="sm"
          iconStart={collapsed ? 'plus' : 'minus'}
          label={expandButtonLabel}
          onClick={toggleExpand}
        />
      </div>
    </Paper>
  );
}

import { useParams } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { Button, useToasts } from '@veo/web-design-system';
import { isRequesterError, isValidationError } from 'src/common/utils/requester-errors';
import { IPlayerKeyInfo } from 'src/common/model/interfaces/IPlayer';
import { useUserSettings } from 'src/common/hooks/user/useUserSettings';
import { Nullable } from 'src/common/model/types/helper.type';
import { useAuthPlayerKeyInfoMutation } from 'src/common/hooks/player/useAuthPlayerKeyInfoMutation';
import { usePlayerOptimisticUpdate } from 'src/common/hooks/player/usePlayerOptimisticUpdate';
import { useUserSettingsMutation } from 'src/common/hooks/user/useUserSettingsMutation';
import { useAnalytics } from 'src/features/analytics/hooks/useAnalytics';
import { MeasurementSystem } from 'src/common/model/enums/MeasurementSystem';
import { getKeyInfoPayload, KeyInfoFormValues } from '../../../utils/key-information';
import { MeasurementSystemField } from './measurement-system-field';
import { HeightField } from './height-field';
import { WeightField } from './weight-field';
import { BirthdayField } from './birthday-field';
import { StrongFootField } from './strong-foot-field';
import { GpaField } from './gpa-field';
import { GraduationField } from './graduation-field';
import styles from './key-information-form.module.scss';

type Props = {
  onCancel: VoidFunction;
  onSubmit: VoidFunction;
};

export function KeyInformationForm({ onCancel, onSubmit }: Props) {
  const { id } = useParams();

  const { analytics, events, statuses } = useAnalytics();

  const {
    formState,
    setError,
    handleSubmit,
  } = useFormContext<KeyInfoFormValues>();

  const {
    isDirty,
    isSubmitting,
    isValid,
  } = formState;

  const Toasts = useToasts();

  const { t } = useTranslation();
  const { data: userSettings } = useUserSettings();

  const { mutateAsync: updateKeyInfo } = useAuthPlayerKeyInfoMutation();
  const { mutate: updateMeasurementSystem } = useUserSettingsMutation();
  const { applyOptimisticUpdate } = usePlayerOptimisticUpdate(id);

  async function handleUpdateMeasurementSystem(ms: Nullable<MeasurementSystem>) {
    const msChanged = ms !== userSettings?.measurementSystem;

    if (userSettings && ms && msChanged) {
      return updateMeasurementSystem({
        onboardingToDo: userSettings.onboardingToDo,
        measurementSystem: ms,
      }, {
        onSuccess() {
          Toasts.add({
            variant: 'success',
            description: t('profile_settings.notification.updated'),
          });
        },
        onError(err) {
          if (isRequesterError(err)) {
            Toasts.add({ variant: 'error', description: err.message });
          }
        },
        onSettled(data) {
          analytics?.track(events.PROFILE_SETTINGS_UPDATED, {
            status: data ? statuses.SUCCESS : statuses.FAILED,
            fields: ['measurementSystem'],
          });
        },
      });
    }

    return Promise.resolve();
  }

  async function submitKeyInfo(data: KeyInfoFormValues) {
    const formattedData = getKeyInfoPayload(data);

    await handleUpdateMeasurementSystem(formattedData.measurementSystem);

    await updateKeyInfo(formattedData as IPlayerKeyInfo, {
      onSuccess(res, optimisticPayload) {
        Toasts.add({
          variant: 'success',
          description: t('player_key_information.form.success_notification'),
        });

        applyOptimisticUpdate(optimisticPayload);

        onSubmit();
      },
      onError(err) {
        if (isValidationError(err)) {
          const { fields } = err;

          const errorKeys = Object.keys(fields) as (keyof KeyInfoFormValues)[];

          errorKeys.forEach((field) => setError(field, { message: fields[field], type: 'server' }));
        }
      },
    });
  }

  return (
    <form
      className={styles.form}
      onSubmit={handleSubmit(submitKeyInfo)}
    >
      <div className={styles.content}>
        <MeasurementSystemField />
        <HeightField />
        <WeightField />
        <BirthdayField />
        <StrongFootField />
        <GpaField />
        <GraduationField />
      </div>

      <div className={cn(styles.controls, styles.field)}>
        <Button
          flexible
          variant="outlined"
          label={t('player_key_information.form.cancel_button_label')}
          disabled={isSubmitting}
          onClick={onCancel}
        />
        <Button
          flexible
          type="submit"
          loading={isSubmitting}
          disabled={!isDirty || !isValid || isSubmitting}
          variant="primary"
          label={t('player_key_information.form.submit_button_label')}
        />
      </div>
    </form>
  );
}

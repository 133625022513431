import { useQuery } from 'react-query';
import { useToasts } from '@veo/web-design-system';
import { RQ_KEY, EXTENDED_CACHE_TIME_MS } from 'src/common/constants/react-query';
import { RequesterError } from 'src/common/model/interfaces/IRequesterError';
import { isRequesterError } from 'src/common/utils/requester-errors';
import { IAbility } from 'src/common/model/interfaces/IAbility';
import { getAbilities } from 'src/common/apis/ability.api';

export const useAbilities = () => {
  const Toasts = useToasts();

  return useQuery<IAbility[], RequesterError>(
    RQ_KEY.ABILITIES,
    () => getAbilities(),
    {
      cacheTime: EXTENDED_CACHE_TIME_MS,
      staleTime: EXTENDED_CACHE_TIME_MS,
      onError(err) {
        if (isRequesterError(err)) {
          Toasts.add({ variant: 'error', description: err.message });
        }
      },
    },
  );
};

import {
  MouseEventHandler, RefObject, useEffect, useState, forwardRef, ForwardedRef,
} from 'react';
import cn from 'classnames';
import { IconButton, Text, Tooltip } from '@veo/web-design-system';
import { useMediaQuery } from 'src/common/hooks/useMediaQuery';
import { useImage } from 'src/common/hooks/useImage';
import { MEDIA_QUERY } from 'src/common/constants/media-query';
import { useMobileDevice } from 'src/common/hooks/useMobileDevice';
import { Paper } from 'src/components/paper/paper';
import { AbilityStack } from 'src/components/ability';
import { PlayerProtected } from 'src/features/auth';
import { togglePlayVideo } from 'src/components/veo-player/utils/player-actions';
import { IPlayerClip } from 'src/common/model/interfaces/IPlayerClip';
import defaultThumbnail from 'src/styles/images/video_thumbnail.webp';
import { EditMenu } from './edit-menu';
import styles from './player-clip-panel.module.scss';

type Props = {
  playerClip: IPlayerClip;
  collapsed: boolean;
  active: boolean;
  videoRef: RefObject<HTMLVideoElement>;
  onClick(pc: IPlayerClip): void;
  onRemove?(pc: IPlayerClip): void;
  onUpdate?(pc: IPlayerClip): void;
};

function PlayerClipPanelCmp(
  {
    playerClip,
    collapsed,
    active,
    videoRef,
    onClick,
    onRemove,
    onUpdate,
  }: Props,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const [isPlaying, setIsPlaying] = useState(false);

  const isMobileLayout = useMediaQuery(MEDIA_QUERY.MOBILE);
  const { isMobile } = useMobileDevice();

  useEffect(() => {
    function trackPlayPause() {
      if (videoRef.current) {
        setIsPlaying(!videoRef.current.paused);
      }
    }

    if (videoRef.current) {
      videoRef.current.addEventListener('play', trackPlayPause);
      videoRef.current.addEventListener('pause', trackPlayPause);
    }

    return () => {
      if (videoRef.current) {
        videoRef.current.removeEventListener('play', trackPlayPause);
        videoRef.current.addEventListener('pause', trackPlayPause);
      }
    };
  }, []);

  const {
    thumbnail,
    title,
    ability,
    playerId,
  } = playerClip;

  const { imageSrc: thumbnailSrc } = useImage(thumbnail, defaultThumbnail);

  const togglePlay: MouseEventHandler<HTMLButtonElement> = () => {
    if (!active) {
      return;
    }

    togglePlayVideo(videoRef);
  };

  const handleClickItem: MouseEventHandler<HTMLDivElement> = () => {
    onClick(playerClip);
  };

  const videoPlayButton = active && (
    <IconButton
      size="md"
      variant="text"
      className={styles['play-button']}
      icon={isPlaying ? 'pause' : 'play'}
      onClick={togglePlay}
    />
  );

  const activeClassname = cn({ [styles.active]: active });

  if (collapsed) {
    return (
      <Tooltip
        portal
        visible={!isMobile && !isMobileLayout ? undefined : false}
        text={title}
      >
        <div
          ref={ref}
          role="button"
          className={cn(styles.collapsed, activeClassname)}
          tabIndex={0}
          onClick={handleClickItem}
        >
          <img
            src={thumbnailSrc}
            className={styles.thumbnail}
            alt={title}
          />
          { videoPlayButton }
        </div>
      </Tooltip>
    );
  }

  const abilityStackProps = isMobile || isMobileLayout
    ? { stackAfter: 2, stackSize: 5 }
    : { stackAfter: 1, stackSize: 5 };

  return (
    <Paper
      ref={ref}
      role="button"
      elevation={2}
      className={cn(styles.expanded, activeClassname)}
      tabIndex={0}
      onClick={handleClickItem}
    >
      <div className={styles['thumbnail-wrapper']}>
        <img
          src={thumbnailSrc}
          className={styles.thumbnail}
          alt={title}
        />
        { videoPlayButton }
      </div>
      <Text
        type="div"
        size="caption"
        weight="medium"
        className={styles.title}
      >
        { title }
      </Text>
      <div className={styles.abilities}>
        <AbilityStack {...abilityStackProps} codes={ability} />
      </div>
      <PlayerProtected predicate={(authPlayer) => authPlayer.id === playerId}>
        <EditMenu
          playerClip={playerClip}
          className={styles.menu}
          onRemove={onRemove}
          onUpdate={onUpdate}
        />
      </PlayerProtected>
    </Paper>
  );
}

export const PlayerClipPanel = forwardRef<HTMLDivElement, Props>(PlayerClipPanelCmp);

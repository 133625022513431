/* eslint-disable max-len */
import { ForwardedRef, forwardRef } from 'react';
import { Text } from '@veo/web-design-system';
import styles from './spotlight.module.scss';

type Props = {
  label?: string;
  x: number;
  y: number;
  width: number;
  height: number;
};

function SpotlightTitleCmp({
  label, x, y, width, height,
}: Props, ref: ForwardedRef<SVGSVGElement>) {
  if (!label) {
    return null;
  }

  return (
    <svg
      ref={ref}
      x={x}
      y={y}
      width={width}
      height={height}
      viewBox="0 0 223 24"
      preserveAspectRatio="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M3.36262 4.42466C2.21927 3.46409 2.89853 1.59961 4.39182 1.59961H177.504C178.529 1.59961 179.53 1.91405 180.371 2.50051L207.024 21.0872C208.308 21.9829 207.674 23.9996 206.109 23.9996H28.4883C27.309 23.9996 26.1674 23.5837 25.2644 22.8251L3.36262 4.42466Z" fill="#3DD64F" />
      <path d="M3.6032 2.85419C2.41461 1.91268 3.08037 0 4.59668 0H185.576C187.533 0 189.447 0.572989 191.083 1.64827L217.933 19.3031C219.26 20.1757 218.643 22.24 217.054 22.24H29.8208C28.6911 22.24 27.5945 21.8583 26.7089 21.1568L3.6032 2.85419Z" fill="#1B221B" />
      <foreignObject x="33" y="3" width="150" height="24">
        <Text className={styles.name} size="body" type="div">
          { label }
        </Text>
      </foreignObject>
    </svg>
  );
}

export const SpotlightTitle = forwardRef<SVGSVGElement, Props>(SpotlightTitleCmp);

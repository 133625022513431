import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { Text, Divider } from '@veo/web-design-system';
import { Position } from 'src/common/model/enums/Position';
import styles from './preferred-positions.module.scss';

const MAX_POSITIONS = 3;

type Props = {
  positions: Position[];
  showEmpty?: boolean;
  className?: string;
};

export function PreferredPositions({ positions, showEmpty = false, className }: Props) {
  const { t } = useTranslation();

  if (!positions.length && !showEmpty) {
    return null;
  }

  const emptyPositions = positions.length < MAX_POSITIONS && showEmpty
    ? new Array(MAX_POSITIONS - positions.length).fill(0).map((_, index) => positions.length + index + 1)
    : [];

  return (
    <div className={cn(styles.container, className)}>
      <Text size="h5" type="div" weight="medium">
        {t('player_position.preferred_positions_title')}
      </Text>
      <Divider />
      {
        positions.map((position, index) => (
          <div key={position} className={styles.row}>
            <Text
              size="body"
              weight="medium"
              type="div"
            >
              { `${index + 1}. `}
              {t(`entity.position.${position}`)}
            </Text>
            <div
              className={cn(
                styles.indicator,
                { [styles[`indicator-${index}`]]: true },
              )}
            />
          </div>
        ))
      }
      {
        emptyPositions.map((order) => (
          <div key={order} className={cn(styles.row, styles.empty)}>
            <Text
              size="body"
              weight="medium"
              type="div"
            >
              { `${order}. `}
              {t('player_position.form.position_field_label')}
            </Text>
            <div
              className={cn(
                styles.indicator,
                { [styles[`indicator-${order - 1}`]]: true },
              )}
            />
          </div>
        ))
      }
    </div>

  );
}

import { useTranslation } from 'react-i18next';
import { useController, useFormContext } from 'react-hook-form';
import { DateTime } from 'luxon';
import { FormField, DateInput } from '@veo/web-design-system';
import { isDateInRangeValidator } from 'src/common/utils/form-validators';
import { KeyInfoFormValues } from '../../../utils/key-information';
import styles from './key-information-form.module.scss';

export function BirthdayField() {
  const { t } = useTranslation();

  const {
    control,
    setValue,
    trigger,
    watch,
  } = useFormContext<KeyInfoFormValues>();

  const value = watch('birthday');

  const isValidDate = (date: string) => {
    const maxBirthdayDate = DateTime.now()
      .minus({ years: 13 })
      .toISODate();

    return isDateInRangeValidator(undefined, maxBirthdayDate)(date);
  };

  const { fieldState: { error } } = useController<KeyInfoFormValues, 'birthday'>({
    control,
    name: 'birthday',
    rules: {
      validate: isValidDate,
    },
  });

  const onUpdate = (date: string) => {
    setValue('birthday', date);
    trigger('birthday');
  };

  return (
    <FormField
      id="birthday"
      className={styles.field}
      label={t('player_key_information.birthdate_title')}
      error={error?.message}
    >
      <DateInput
        clearable
        updateOnChange
        value={value}
        onUpdate={onUpdate}
        onBlur={(e) => {
          // Blur event handler is needed to catch manual input (or deletion) of the date
          if (e.target.value !== value) {
            onUpdate(e.target.value);
          }
        }}
      />
    </FormField>
  );
}

import { useMutation, useQueryClient } from 'react-query';
import { useToasts } from '@veo/web-design-system';
import { updatePlayerAboutInfo } from 'src/common/apis/player.api';
import { RequesterError } from 'src/common/model/interfaces/IRequesterError';
import { isRequesterError } from 'src/common/utils/requester-errors';
import { RQ_KEY } from 'src/common/constants/react-query';
import { IPlayerAboutInfo } from 'src/common/model/interfaces/IPlayer';
import { useAnalytics } from 'src/features/analytics/hooks/useAnalytics';

export function useAuthPlayerAboutInfoMutation() {
  const Toasts = useToasts();
  const { analytics, events, statuses } = useAnalytics();
  const queryClient = useQueryClient();

  return useMutation<unknown, RequesterError, IPlayerAboutInfo>(
    (data) => updatePlayerAboutInfo(data),
    {
      async onSuccess() {
        queryClient.invalidateQueries(RQ_KEY.AUTH_USER);
      },
      async onError(err) {
        if (isRequesterError(err)) {
          Toasts.add({ variant: 'error', description: err.message });
        }
      },
      async onSettled(data, err) {
        const status = err ? statuses.FAILED : statuses.SUCCESS;

        analytics?.track(events.ABOUT_INFO_UPDATED, { status });
      },
    },
  );
}

import { useMutation, useQueryClient } from 'react-query';
import { useToasts } from '@veo/web-design-system';
import { deletePlayerClip } from 'src/common/apis/player-clips';
import { RequesterError } from 'src/common/model/interfaces/IRequesterError';
import { isRequesterError } from 'src/common/utils/requester-errors';
import { useAnalytics } from 'src/features/analytics/hooks/useAnalytics';
import { RQ_KEY } from 'src/common/constants/react-query';
import { useAuthPlayer } from 'src/features/auth';

export function useDeletePlayerClipMutation() {
  const { data: authPlayer } = useAuthPlayer();
  const Toasts = useToasts();
  const { analytics, events, statuses } = useAnalytics();
  const queryClient = useQueryClient();

  return useMutation<unknown, RequesterError, string>(
    (playerClipId) => deletePlayerClip(playerClipId),
    {
      async onSuccess() {
        /** Invalidating all player clips queries (both own and public) since there might be different query params in each of them */
        queryClient.invalidateQueries(RQ_KEY.PLAYER_CLIPS);
        /** Invalidating player abilities query since it might add new one */
        queryClient.invalidateQueries([RQ_KEY.PLAYER_ABILITIES, { playerId: authPlayer?.id }]);
      },
      async onError(err) {
        if (isRequesterError(err)) {
          Toasts.add({ variant: 'error', description: err.message });
        }
      },
      async onSettled(data, err) {
        const status = err ? statuses.FAILED : statuses.SUCCESS;
        const action = 'remove';

        analytics?.track(events.PLAYER_CLIPS_CHANGED, { status, action });
      },
    },
  );
}

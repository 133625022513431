import { useTranslation } from 'react-i18next';
import { Button, useToasts } from '@veo/web-design-system';
import { getProfileExternalLink } from 'src/common/utils/player-profile';
import { useCopyToClipboard } from 'src/common/hooks/useCopyToClipboard';
import { IPlayer } from 'src/common/model/interfaces/IPlayer';
import { useMediaQuery } from 'src/common/hooks/useMediaQuery';
import { MEDIA_QUERY } from 'src/common/constants/media-query';

type Props = {
  player?: IPlayer;
};

export function ShareProfile({ player }: Props) {
  const { t } = useTranslation();
  const Toasts = useToasts();
  const [, setToClipboard] = useCopyToClipboard();

  const isMobileLayout = useMediaQuery(MEDIA_QUERY.MOBILE);

  function handleCopyToClipboard() {
    if (player) {
      const profileUrl = getProfileExternalLink(player);

      setToClipboard(profileUrl);

      Toasts.add({
        variant: 'info',
        description: t('share_profile.share_notification_label'),
      });
    }
  }

  return (
    <Button
      flexible={isMobileLayout}
      size="md"
      variant={isMobileLayout ? 'primary' : 'text'}
      iconEnd="share"
      label={t('share_profile.share_button_label')}
      onClick={handleCopyToClipboard}
    />
  );
}

import { useMutation, useQueryClient } from 'react-query';
import { useToasts } from '@veo/web-design-system';
import { orderPlayerClips } from 'src/common/apis/player-clips';
import { RequesterError } from 'src/common/model/interfaces/IRequesterError';
import { isRequesterError } from 'src/common/utils/requester-errors';
import { useAnalytics } from 'src/features/analytics/hooks/useAnalytics';
import { RQ_KEY } from 'src/common/constants/react-query';
import { IPlayerClip } from 'src/common/model/interfaces/IPlayerClip';

export function useOrderPlayerClipsMutation() {
  const Toasts = useToasts();
  const { analytics, events, statuses } = useAnalytics();
  const queryClient = useQueryClient();

  return useMutation<unknown, RequesterError, IPlayerClip[]>(
    (playerClips) => orderPlayerClips(playerClips),
    {
      async onSuccess() {
        /** Invalidating all player clips queries (both own and public) since there might be different query params in each of them */
        queryClient.invalidateQueries(RQ_KEY.PLAYER_CLIPS);
      },
      async onError(err) {
        if (isRequesterError(err)) {
          Toasts.add({ variant: 'error', description: err.message });
        }
      },
      async onSettled(data, err) {
        const status = err ? statuses.FAILED : statuses.SUCCESS;
        const action = 'order';

        analytics?.track(events.PLAYER_CLIPS_CHANGED, { action, status });
      },
    },
  );
}

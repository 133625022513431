import { useMutation } from 'react-query';
import { useToasts } from '@veo/web-design-system';
import { detectTelestration } from 'src/common/apis/telestration';
import { RequesterError } from 'src/common/model/interfaces/IRequesterError';
import { ITelestration, ICapturedFramePayload } from 'src/common/model/interfaces/ITelestration';
import { isRequesterError } from 'src/common/utils/requester-errors';
import { useAnalytics } from 'src/features/analytics/hooks/useAnalytics';

export function useParsedSpotlightMutation() {
  const Toasts = useToasts();
  const { analytics, statuses, events } = useAnalytics();

  return useMutation<ITelestration, RequesterError, ICapturedFramePayload>(
    (payload) => detectTelestration(payload),
    {
      async onError(err) {
        if (isRequesterError(err)) {
          Toasts.add({ variant: 'error', description: err.message });
        }
      },
      async onSettled(res, err) {
        const status = err ? statuses.FAILED : statuses.SUCCESS;

        analytics?.track(events.PLAYER_DETECTION, { status });
      },
    },
  );
}

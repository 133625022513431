import { useMutation, useQueryClient } from 'react-query';
import { useToasts } from '@veo/web-design-system';
import { deletePlayerClipTelestration } from 'src/common/apis/telestration';
import { RequesterError } from 'src/common/model/interfaces/IRequesterError';
import { ITelestration } from 'src/common/model/interfaces/ITelestration';
import { isRequesterError } from 'src/common/utils/requester-errors';
import { useAnalytics } from 'src/features/analytics/hooks/useAnalytics';
import { RQ_KEY } from 'src/common/constants/react-query';

export type RemoveTelestrationPayload = {
  telestration: ITelestration,
  playerClipId: string,
};

export function useRemoveTelestrationMutation() {
  const queryClient = useQueryClient();
  const Toasts = useToasts();
  const { analytics, statuses, events } = useAnalytics();

  return useMutation<unknown, RequesterError, RemoveTelestrationPayload>(
    ({ telestration }) => deletePlayerClipTelestration(telestration.items[0].id),
    {
      async onSuccess() {
        /** Invalidating all player clips queries (both own and public) since there might be different query params in each of them */
        queryClient.invalidateQueries(RQ_KEY.PLAYER_CLIPS);
      },
      async onError(err) {
        if (isRequesterError(err)) {
          Toasts.add({ variant: 'error', description: err.message });
        }
      },
      async onSettled(res, err) {
        const status = err ? statuses.FAILED : statuses.SUCCESS;

        analytics?.track(
          events.TELESTRATION_CHANGED,
          { action: 'Remove', status, type: 'N/A' },
        );
      },
    },
  );
}

import { Icon, IconName, Text } from '@veo/web-design-system';
import styles from './clip-item.module.scss';

type Props = {
  status: string;
  icon?: IconName;
};

export function ClipStatusTag({ status, icon = 'lock' }: Props) {
  return (
    <div className={styles['added-indicator']}>
      <Icon size="sm" name={icon} />
      <Text type="div">
        { status }
      </Text>
    </div>
  );
}

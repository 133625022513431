import { useState, useRef, useEffect } from 'react';
import ReactCrop, { Crop, PixelCrop } from 'react-image-crop';
import { IconButton } from '@veo/web-design-system';
import { OutputFormats } from './constants';
import {
  EditableImage,
  DimensionCrop,
  AspectCrop,
  OutputFormat,
  isDimensionCrop,
  isCropConfig,
} from './types';
import {
  getEditableImage,
  getCropAspect,
  centerAspectCrop,
  getCroppedImage,
} from './utils';
import 'react-image-crop/dist/ReactCrop.css';
import styles from './image-crop.module.scss';

export type BaseProps = {
  image: File;
  alt: string;
  format?: OutputFormat;
  onCrop(image: Blob): void;
  onCancel?(): void;
  onError?(err: unknown): void;
};

export type AspectProps = AspectCrop & BaseProps;
export type DimensionProps = DimensionCrop & BaseProps;

export function ImageCrop({
  image,
  alt,
  format = OutputFormats.jpeg,
  onCrop,
  onCancel,
  onError,
  ...configProps
}: AspectProps | DimensionProps) {
  const [editableImage, setEditableImage] = useState<EditableImage | null>(null);
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const imageRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    let cleanUpUrl: string;

    async function registerEditableImage(i: File) {
      try {
        const data = await getEditableImage(i);

        const { width, height, url } = data;

        cleanUpUrl = url;

        setEditableImage(data);

        if (isCropConfig(configProps)) {
          const cropAspect = getCropAspect(configProps);

          setCrop(centerAspectCrop(width, height, cropAspect!));
        }
      } catch (error) {
        if (error && onError) {
          onError(error);
        }
      }
    }

    if (image) {
      registerEditableImage(image);
    }

    return () => {
      if (cleanUpUrl) {
        URL.revokeObjectURL(cleanUpUrl);
      }
    };
  }, [image]);

  async function handleSaveCrop() {
    if (imageRef.current && completedCrop && editableImage) {
      try {
        const saveCropConfig = isDimensionCrop(configProps)
          ? { format, ...configProps }
          : { format };

        const croppedImageBlob = await getCroppedImage(imageRef.current, completedCrop, saveCropConfig);

        onCrop(croppedImageBlob as Blob);

        URL.revokeObjectURL(editableImage.url);
      } catch (error) {
        if (error && onError) {
          onError(error);
        }
      }
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <ReactCrop
          keepSelection
          aspect={getCropAspect(configProps)}
          crop={crop}
          onChange={(_, percentCrop) => setCrop(percentCrop)}
          onComplete={(pixelCrop) => setCompletedCrop(pixelCrop)}
        >
          <img
            ref={imageRef}
            style={{ aspectRatio: editableImage ? editableImage.width / editableImage.height : undefined }}
            className={styles.img}
            src={editableImage?.url}
            alt={alt}
          />
        </ReactCrop>
      </div>
      <div className={styles.controls}>
        {
          onCancel && (
            <IconButton
              size="md"
              variant="outlined"
              icon="x"
              onClick={() => onCancel()}
            />
          )
        }
        <IconButton
          size="md"
          variant="primary"
          disabled={!completedCrop}
          icon="check"
          onClick={handleSaveCrop}
        />
      </div>
    </div>
  );
}

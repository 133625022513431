import { Button, Text } from '@veo/web-design-system';
import { useTranslation } from 'react-i18next';
import { OidcProtected, OidcPublic, useOidcAuth } from 'src/features/oidc';
import bgPicture from 'src/styles/images/football_player.webp';
import { TeamMemberList } from './components/team-member-list/team-member-list';
import { UserBanner } from './components/user-banner/user-banner';
import { usePageViewEvent } from '../analytics/hooks/usePageViewEvent';
import { PAGE_VIEW_EVENTS } from '../analytics/constants/segment-events';
import styles from './home.module.scss';

export function Home() {
  const { signinRedirect } = useOidcAuth();
  const { t } = useTranslation();

  usePageViewEvent(PAGE_VIEW_EVENTS.HOME_PAGE_VISITED);

  return (
    <div className={styles.container}>
      <OidcPublic>
        <div className={styles['public-container']}>
          <div className={styles.info}>
            <Text size="h1" type="h1" weight="medium">
              {t('home.title')}
            </Text>
            <Text size="h3" type="h3" weight="medium">
              {t('home.description')}
            </Text>
            <Text size="body" type="div">
              {t('home.message')}
            </Text>
            <div className={styles.actions}>
              <Button
                variant="outlined"
                label={t('auth.login_button_label')}
                iconEnd="log-in"
                onClick={() => signinRedirect()}
              />
            </div>
          </div>
          <div className={styles.pictures}>
            <img
              src={bgPicture}
              alt="Football player"
              className={styles.image}
            />
          </div>
        </div>
      </OidcPublic>
      <OidcProtected>
        <UserBanner />
        <TeamMemberList />
      </OidcProtected>
    </div>
  );
}

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import cn from 'classnames';
import { Button, UploadImage, useToasts } from '@veo/web-design-system';
import { IPlayer } from 'src/common/model/interfaces/IPlayer';
import { RQ_KEY } from 'src/common/constants/react-query';
import { ImageCrop } from 'src/components/image-crop/image-crop';
import { Nullable } from 'src/common/model/types/helper.type';
import { isValidationError } from 'src/common/utils/requester-errors';
import { useQueryClient } from 'react-query';
import { useAuthPlayerImageMutation } from 'src/common/hooks/player/useAuthPlayerImageMutation';
import { AboutImageFormValues } from '../../utils/about-information';
import styles from './profile-image-editor.module.scss';

type Props = {
  player: IPlayer;
  onSubmit: VoidFunction;
  onCancel: VoidFunction;
};

export function ProfileImageEditor({ player, onSubmit, onCancel }: Props) {
  const [croppedImage, setCroppedImage] = useState<Nullable<Blob>>(null);
  const [rawImage, setRawImage] = useState<Nullable<File>>(null);
  const [previewUrl, setPreviewUrl] = useState(player?.image || null);

  const {
    formState,
    setValue,
    setError,
    reset,
  } = useFormContext<AboutImageFormValues>();
  const { isDirty, errors } = formState;

  const Toasts = useToasts();

  const queryClient = useQueryClient();

  const { id } = useParams();

  const {
    mutate: updatePlayerImage,
    isLoading: isSubmitting,
  } = useAuthPlayerImageMutation();

  const { t } = useTranslation();

  function handleImageChange(f: File) {
    setRawImage(f);
    setValue('image', 'raw-image', { shouldDirty: true });
  }

  async function handleSubmit() {
    if (player) {
      const payload = {
        firstName: player.firstName,
        lastName: player.lastName,
        image: croppedImage,
        id: player.id,
      };

      updatePlayerImage(payload, {
        onSuccess() {
          queryClient.invalidateQueries([RQ_KEY.PROFILE_INFO, id]);

          Toasts.add({
            variant: 'success',
            description: t('player_about.form.image_success_notification'),
          });

          onSubmit();
        },
        onError(err) {
          if (isValidationError(err)) {
            const message = err.fields?.image ?? 'Something went wrong';

            setError('image', { message, type: 'server' });
          }
        },
      });
    }
  }

  function handleReset() {
    setRawImage(null);
    setCroppedImage(null);

    const preview = player?.image || null;
    setPreviewUrl(preview);
    reset();
  }

  function handleRemoveImage() {
    setRawImage(null);
    setRawImage(null);
    setPreviewUrl(null);
    setValue('image', '', { shouldDirty: true });
  }

  function handleCrop(image: Blob) {
    const croppedImageUrl = URL.createObjectURL(image);

    setRawImage(null);
    setCroppedImage(image);
    setPreviewUrl(croppedImageUrl);
    setValue('image', croppedImageUrl, { shouldDirty: true });
  }

  return (
    <div className={styles.container}>
      {
        !rawImage && (
          <UploadImage
            width={200}
            height={250}
            error={errors?.image?.message ?? undefined}
            image={previewUrl || undefined}
            onUploadImage={handleImageChange}
            onRemoveImage={handleRemoveImage}
          />
        )
      }
      {
        !!rawImage && (
          <ImageCrop
            image={rawImage}
            outputWidth={384}
            outputHeight={480}
            alt={`${player.firstName} ${player.lastName} profile picture`}
            onCrop={handleCrop}
            onCancel={handleReset}
          />
        )
      }
      <div className={cn(styles.controls, { [styles.hidden]: !!rawImage })}>
        <Button
          flexible
          variant="outlined"
          label={t('player_about.form.cancel_button_label')}
          disabled={isSubmitting}
          onClick={onCancel}
        />
        <Button
          flexible
          loading={isSubmitting}
          disabled={!isDirty || isSubmitting}
          variant="primary"
          label={t('player_about.form.submit_button_label')}
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
}

import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { GlobalStyles as VeoGlobalStyles, ToastContextProvider } from '@veo/web-design-system';
import { AnalyticsProvider } from 'src/features/analytics/hooks/useAnalytics';
import { OidcProvider } from 'src/features/oidc';
import { DEFAULT_QUERY_CLIENT_CONFIG } from './common/constants/react-query';
import i18n from './common/services/i18next';
import { App } from './App';

const queryClient = new QueryClient(DEFAULT_QUERY_CLIENT_CONFIG);

export function Main() {
  const TOAST_TITLES = {
    error: i18n.t('toasts.error.title'),
    info: i18n.t('toasts.info.title'),
    success: i18n.t('toasts.success.title'),
    warning: i18n.t('toasts.warning.title'),
  };

  return (
    <VeoGlobalStyles>
      <AnalyticsProvider>
        <OidcProvider>
          <QueryClientProvider client={queryClient}>
            <BrowserRouter>
              <ToastContextProvider titles={TOAST_TITLES}>
                <App />
              </ToastContextProvider>
            </BrowserRouter>
          </QueryClientProvider>
        </OidcProvider>
      </AnalyticsProvider>
    </VeoGlobalStyles>
  );
}

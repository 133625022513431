import { useQuery } from 'react-query';
import { useToasts } from '@veo/web-design-system';
import { RQ_KEY, EXTENDED_CACHE_TIME_MS } from 'src/common/constants/react-query';
import { RequesterError } from 'src/common/model/interfaces/IRequesterError';
import { isRequesterError } from 'src/common/utils/requester-errors';
import { IAbility } from 'src/common/model/interfaces/IAbility';
import { getPlayerAbilities } from 'src/common/apis/ability.api';

export const usePlayerAbilities = (playerId?: string) => {
  const Toasts = useToasts();

  return useQuery<IAbility[], RequesterError>(
    [RQ_KEY.PLAYER_ABILITIES, { playerId }],
    () => getPlayerAbilities(playerId!),
    {
      enabled: !!playerId,
      cacheTime: EXTENDED_CACHE_TIME_MS,
      staleTime: EXTENDED_CACHE_TIME_MS,
      onError(err) {
        if (isRequesterError(err)) {
          Toasts.add({ variant: 'error', description: err.message });
        }
      },
    },
  );
};

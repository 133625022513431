import { useTranslation } from 'react-i18next';
import { take } from 'lodash-es';
import { Text, Tooltip } from '@veo/web-design-system';
import { useAbilities } from 'src/common/hooks/abilities/useAbilities';
import { AbilityCode } from 'src/common/model/enums/AbilityCode';
import { ColorBar } from './color-bar';
import { AbilityItem } from './ability-item';
import { AbilityTag } from './ability-tag';
import styles from './ability.module.scss';

type Props = {
  codes?: AbilityCode[];
  stackAfter?: number;
  showTooltip?: boolean;
  stackSize?: number;
};

export function AbilityStack({
  codes, showTooltip, stackAfter = 1, stackSize = 3,
}: Props) {
  const { t } = useTranslation();

  const { data } = useAbilities();

  if (!codes?.length || !data) {
    return null;
  }

  const safeStackAfter = Math.min(stackAfter, codes.length);

  const fullyDisplayedCodes = codes.slice(0, safeStackAfter);
  const restCodes = codes.slice(safeStackAfter);

  const tooltipText = restCodes.map((c) => t(`entity.category.${c}`)).join(', ');

  const fullAbilitiesContent = <>{fullyDisplayedCodes.map((ac) => <AbilityItem key={ac} code={ac} />)}</>;

  if (!restCodes || !restCodes.length) {
    return fullAbilitiesContent;
  }

  return (
    <>
      { fullAbilitiesContent }
      <Tooltip
        visible={showTooltip}
        portal
        text={tooltipText}
      >
        <AbilityTag className={styles.stack}>
          {
            take(restCodes, stackSize).map((c) => {
              const aColor = data.find(({ code }) => code === c)?.color;

              return <ColorBar key={c} color={aColor!} />;
            })
          }
          <Text
            type="div"
            size="caption"
            weight="semiBold"
          >
            {restCodes.length}
            +
          </Text>
        </AbilityTag>
      </Tooltip>
    </>
  );
}

import { useState } from 'react';
import { isEqual } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { Modal, Button, ModalHeader } from '@veo/web-design-system';
import { useMediaQuery } from 'src/common/hooks/useMediaQuery';
import { MEDIA_QUERY } from 'src/common/constants/media-query';
import { Confirmation, useConfirmation } from 'src/components/confirmation';
import { useUpdatePlayerClipMutation } from 'src/common/hooks/player-clips/useUpdatePlayerClipMutation';
import { useAnalytics } from 'src/features/analytics/hooks/useAnalytics';
import { AnalyticEventsType } from 'src/features/analytics/constants/segment-events';
import { IPlayerClip } from 'src/common/model/interfaces/IPlayerClip';
import { PlayerClipDetails } from 'src/features/player-clip-details/player-clip-details';
import { useCreateTelestrationMutation, useRemoveTelestrationMutation } from 'src/features/telestration';
import styles from './edit-modal.module.scss';

type Props = {
  playerClip: IPlayerClip;
  open: boolean;
  onClose: VoidFunction;
  onUpdate?(pc: IPlayerClip): void;
};

type ChangeEvent = {
  name: AnalyticEventsType,
  props: Record<string, any>,
};

// todo: Implement switching ActiveClip to the edited one
export function EditModal({
  playerClip,
  open,
  onClose,
  onUpdate,
}: Props) {
  const [updatedItem, setUpdatedItem] = useState<IPlayerClip>(playerClip);

  const { t } = useTranslation();

  const { analytics, events, statuses } = useAnalytics();

  const isMobileLayout = useMediaQuery(MEDIA_QUERY.MOBILE);

  const { mutateAsync: updatePlayerClip, isLoading: isUpdating } = useUpdatePlayerClipMutation();
  const { mutateAsync: createTelestration, isLoading: isCreating } = useCreateTelestrationMutation();
  const { mutateAsync: removeTelestration, isLoading: isRemoving } = useRemoveTelestrationMutation();

  const isUpdated = !isEqual(updatedItem, playerClip);

  function handleCloseAndReset() {
    onClose();

    setUpdatedItem(playerClip);
  }

  const {
    triggerAction: closeWithConfirmation,
    ...restConfirmationProps
  } = useConfirmation({ required: isUpdated, action: handleCloseAndReset });

  function handlePlayerClipChange(pc: IPlayerClip) {
    setUpdatedItem(pc);
  }

  async function trackChangeEvent(changeRequest: Promise<unknown>, event: ChangeEvent) {
    const { name, props } = event;

    await changeRequest
      .then(() => analytics?.track(name, { ...props, status: statuses.SUCCESS }))
      .catch(() => analytics?.track(name, { ...props, status: statuses.FAILED }));
  }

  async function updateItem() {
    if (isUpdated && !isEqual(playerClip.ability, updatedItem.ability)) {
      // Now its only ability later may be extended with other actions
      const eventPayload = {
        action: 'ability',
      };

      trackChangeEvent(updatePlayerClip(updatedItem), { name: events.PLAYER_CLIPS_CHANGED, props: eventPayload });
    }

    if (isUpdated && onUpdate) {
      onUpdate(updatedItem);
    }

    onClose();
  }

  async function updateItemTelestration() {
    const { telestration, id: playerClipId } = playerClip;
    const { telestration: newTelestration } = updatedItem;

    if (!isEqual(newTelestration, telestration) && newTelestration) {
      await createTelestration({ playerClipId, telestration: newTelestration });
    }

    if (!isEqual(newTelestration, telestration) && !newTelestration && telestration) {
      await removeTelestration({ playerClipId, telestration });
    }

    updateItem();
  }

  const isProcessing = isCreating || isUpdating || isRemoving;

  return (
    <>
      <Confirmation
        title={t('player_clip_details.edit.discard_confirmation_title')}
        message={t('player_clip_details.edit.discard_confirmation_description') as string}
        confirmLabel={t('player_clip_details.edit.discard_confirmation_confirm_button_label') as string}
        cancelLabel={t('player_clip_details.edit.discard_confirmation_cancel_button_label') as string}
        {...restConfirmationProps}
      />
      <Modal
        open={open}
        onClose={closeWithConfirmation}
        className={styles.modal}
      >
        <ModalHeader
          title={t('player_clip_details.title')}
          description={playerClip.title}
        />
        <div className={styles.container}>
          <PlayerClipDetails
            playerClip={updatedItem}
            onChange={handlePlayerClipChange}
          />
          <Button
            flexible={isMobileLayout}
            variant="primary"
            size="md"
            loading={isProcessing}
            disabled={!isUpdated || isProcessing}
            iconEnd="check"
            className={styles['update-button']}
            label={t('profile_video.player_clip.confirm_update_button_label')}
            onClick={updateItemTelestration}
          />
        </div>
      </Modal>
    </>
  );
}

import {
  useEffect, useState, useRef, CSSProperties,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Spinner, Text, Button, IconButton,
} from '@veo/web-design-system';
import { useAuthPlayer } from 'src/features/auth';
import { MEDIA_QUERY } from 'src/common/constants/media-query';
import { useMediaQuery } from 'src/common/hooks/useMediaQuery';
import { ITelestrationItem, ITelestration } from 'src/common/model/interfaces/ITelestration';
import { Nullable } from 'src/common/model/types/helper.type';
import { useViewportSize } from 'src/common/hooks/useViewportSize';
import { CapturedFrame } from '../../models/captured-frame';
import { Spotlight } from '../spotlight/spotlight';
import { TELESTRATION_TYPES } from '../../constants/telestration';
import { TelestrationCanvas } from '../telestration-canvas/telestration-canvas';
import { OverlayHint } from '../overlay-hint/overlay-hint';
import { useReminder } from '../../hooks/useReminder';
import { FramePaner } from '../frame-paner/frame-paner';
import { CustomSpotlight } from '../custom-spotlight/custom-spotlight';
import { CustomSpotlightCanvas } from '../custom-spotlight/custom-spotlight-canvas';
import { convertCoordsToSvg, getCustomSpotlight } from '../../utils/parsed-telestration';
import { formatFloat } from '../../utils/video';
import styles from './player-spotlight-picker.module.scss';

type Props = {
  frame: Nullable<CapturedFrame>;
  telestration?: ITelestration;
  loading: boolean;
  onChange(telestration: ITelestrationItem): void;
  onCancel: VoidFunction;
};

type PaningConfig = {
  min: number;
  max: number;
  current: number;
};

export function PlayerSpotlightPicker({
  frame, telestration, loading, onChange, onCancel,
}: Props) {
  const { t } = useTranslation();
  const [selectedSpotlight, setSelectedSpotlight] = useState<ITelestrationItem | null>(null);
  const [customSpotlight, setCustomSpotlight] = useState<ITelestrationItem | null>(null);
  const [paning, setPaning] = useState<PaningConfig>({ min: 0, max: 0, current: 0 });
  const [hoveredSpotlightId, setHoveredSpotlightId] = useState<string | null>(null);
  const svgContainerRef = useRef<HTMLDivElement>(null);

  const { data: authPlayer } = useAuthPlayer();
  const { reminder, clearReminder } = useReminder();

  const isMobileLayout = useMediaQuery(MEDIA_QUERY.MOBILE);

  const viewportSize = useViewportSize();

  useEffect(() => {
    if (svgContainerRef.current && isMobileLayout) {
      const { width: viewportWidth } = viewportSize;
      const { width: containerWidth } = svgContainerRef.current.getBoundingClientRect();

      const hiddenDelta = formatFloat((viewportWidth - containerWidth) / 2, 0);

      setPaning((prev) => ({ ...prev, min: hiddenDelta, max: Math.abs(hiddenDelta) }));
    }
  }, []);

  useEffect(() => {
    if (hoveredSpotlightId) {
      clearReminder();
    }
  }, [hoveredSpotlightId]);

  function handlePanChange(value: number) {
    setPaning((prev) => ({ ...prev, current: value }));
  }

  function addCustomSpotlight() {
    if (!frame) {
      return;
    }

    setSelectedSpotlight(null);
    clearReminder();

    const { x: canvasXDecrese } = convertCoordsToSvg({
      point: { x: paning.current, y: 0 },
      canvas: frame.canvas,
      viewContainerRef: svgContainerRef,
    });

    const customNewSpotlightCanvas = {
      width: frame.canvas.width + 2 * canvasXDecrese,
      height: frame.canvas.height,
    };

    const initialPoints = getCustomSpotlight(customNewSpotlightCanvas);

    const spotlight: ITelestrationItem = {
      id: `${Date.now()}`,
      probability: 101,
      type: TELESTRATION_TYPES.BOX,
      timestamp: frame.timestamp,
      points: initialPoints,
    };

    setCustomSpotlight(spotlight);
  }

  const playerName = `${authPlayer?.jerseyNumber ?? ''} ${authPlayer?.firstName} ${authPlayer?.lastName}`.trim();

  if (!frame) {
    return null;
  }

  const { width: canvasWidth, height: canvasHeight } = frame.canvas;

  const renderAIBoundingBoxes = () => {
    if (!customSpotlight) {
      return (
        <>
          <TelestrationCanvas
            width={canvasWidth}
            height={canvasHeight}
            className={styles['translate-canvas']}
          >
            <image width={canvasWidth} height={canvasHeight} href={frame.url} />
            {
              telestration?.items.map((parsedBox) => (
                <Spotlight
                  visible={parsedBox.id === hoveredSpotlightId || parsedBox.id === selectedSpotlight?.id}
                  boundingBoxVisible={reminder}
                  onSpotlightClick={() => setSelectedSpotlight(parsedBox)}
                  onSpotlightOver={() => setHoveredSpotlightId(parsedBox.id)}
                  onSpotlightLeave={() => setHoveredSpotlightId(null)}
                  key={parsedBox.id}
                  boundingBox={parsedBox}
                  title={playerName}
                />
              ))
            }
          </TelestrationCanvas>
          <OverlayHint
            show={!loading}
            label={selectedSpotlight
              ? t('telestration.player_spotlight_picker.confirmation_hint')
              : t('telestration.player_spotlight_picker.select_hint')}
          >
            <div className={styles.confirmation}>
              <IconButton
                size="sm"
                icon="x"
                variant="primary"
                onClick={onCancel}
              />
              <IconButton
                icon="check"
                size="sm"
                disabled={!selectedSpotlight}
                variant="primary"
                onClick={() => onChange(selectedSpotlight!)}
              />
              <Button
                label={t('telestration.player_spotlight_picker.add_manually_label')}
                size="sm"
                variant="primary"
                onClick={addCustomSpotlight}
              />
            </div>
          </OverlayHint>
        </>
      );
    }

    return null;
  };

  const renderCustomBoundingBox = () => {
    if (customSpotlight) {
      return (
        <>
          <CustomSpotlightCanvas
            data={customSpotlight}
            containerRef={svgContainerRef}
            width={canvasWidth}
            height={canvasHeight}
            className={styles['translate-canvas']}
            onDragEnd={setCustomSpotlight}
          >
            <image width={canvasWidth} height={canvasHeight} href={frame.url} />
            <CustomSpotlight
              containerRef={svgContainerRef}
              canvas={frame.canvas}
              title={playerName}
              data={customSpotlight}
              onChange={setCustomSpotlight}
            />
          </CustomSpotlightCanvas>
          <OverlayHint
            label={t('telestration.player_spotlight_picker.manual_spotlight_hint')}
            show={!loading}
          >
            <div className={styles.confirmation}>
              <IconButton
                size="sm"
                icon="x"
                variant="primary"
                onClick={onCancel}
              />
              <IconButton
                icon="check"
                size="sm"
                variant="primary"
                onClick={() => onChange(customSpotlight!)}
              />
            </div>
          </OverlayHint>
        </>
      );
    }

    return null;
  };

  function renderLoading() {
    if (!loading) {
      return null;
    }

    return (
      <div className={styles.loader}>
        <Spinner size="md" />
        <Text type="div" size="h6" weight="medium">
          { t('telestration.player_spotlight_picker.detecting_label') }
        </Text>
      </div>
    );
  }

  const translateCanvasStyles = { '--canvas-translateX': `${-1 * paning.current}px` } as CSSProperties;

  return (
    <div className={styles.container}>
      {
        isMobileLayout && (
          <div className={styles.controls}>
            <FramePaner
              img={frame.url}
              value={paning.current}
              min={paning.min}
              max={paning.max}
              onChange={handlePanChange}
            />
          </div>
        )
      }
      <div
        ref={svgContainerRef}
        className={styles.telestrations}
        style={translateCanvasStyles}
      >
        { renderAIBoundingBoxes() }
        { renderCustomBoundingBox() }
        { renderLoading() }
      </div>
    </div>
  );
}

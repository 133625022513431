import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import { Button, useToasts } from '@veo/web-design-system';
import { useAuthPlayerPositionMutation } from 'src/common/hooks/player/useAuthPlayerPositionMutation';
import { isValidationError } from 'src/common/utils/requester-errors';
import { usePlayerOptimisticUpdate } from 'src/common/hooks/player/usePlayerOptimisticUpdate';
import { IPlayerPositionInfo } from 'src/common/model/interfaces/IPlayer';
import { PositionPlaystyleFormValues, getPositionPlaystylePayload } from '../../../utils/position-information';
import { PositionField } from './position-field';
import { PlaystyleField } from './playstyle-field';
import styles from './position-information-form.module.scss';

type Props = {
  onSubmit: VoidFunction;
  onCancel: VoidFunction;
};

export function PositionInformationForm({ onCancel, onSubmit }: Props) {
  const Toasts = useToasts();

  const { id } = useParams();

  const { t } = useTranslation();

  const { formState, setError, handleSubmit } = useFormContext<PositionPlaystyleFormValues>();
  const {
    isDirty,
    isSubmitting,
    isValid,
  } = formState;

  const { mutate: updatePlayerPositions } = useAuthPlayerPositionMutation();
  const { applyOptimisticUpdate } = usePlayerOptimisticUpdate(id);

  function submitPosition(data: PositionPlaystyleFormValues) {
    const formattedData = getPositionPlaystylePayload(data);

    updatePlayerPositions(formattedData, {
      onError(err) {
        if (isValidationError(err)) {
          const { fields } = err;

          const errorKeys = Object.keys(fields) as (keyof IPlayerPositionInfo)[];

          errorKeys.forEach((key) => {
            if (key === 'position' || key === 'additionalPositions') {
              setError('positions', { message: fields[key], type: 'server' });
            } else {
              setError(key, { message: fields[key], type: 'server' });
            }
          });
        }
      },
      onSuccess(res, optimisticPayload) {
        Toasts.add({
          variant: 'success',
          description: t('player_position.form.success_notification'),
        });

        applyOptimisticUpdate(optimisticPayload);

        onSubmit();
      },
    });
  }

  return (
    <form
      className={styles.form}
      onSubmit={handleSubmit(submitPosition)}
    >
      <div className={styles.content}>
        <PositionField />
        <PlaystyleField />
      </div>
      <div className={styles.controls}>
        <Button
          flexible
          variant="outlined"
          disabled={isSubmitting}
          label={t('player_position.form.cancel_button_label')}
          onClick={onCancel}
        />
        <Button
          flexible
          variant="primary"
          type="submit"
          disabled={!isDirty || !isValid || isSubmitting}
          loading={isSubmitting}
          label={t('player_position.form.submit_button_label')}
        />
      </div>
    </form>
  );
}

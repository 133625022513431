import { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Text, Button } from '@veo/web-design-system';
import { useAnalytics } from 'src/features/analytics';
import { IPlayer } from 'src/common/model/interfaces/IPlayer';
import { SoccerPitch } from 'src/features/pitch/soccer-pitch';
import { PitchPositions } from 'src/features/pitch/components/pitch-positions/pitch-positions';
import { PlayerProtected } from 'src/features/auth';
import { PositionInformationModal } from './position-information-modal';
import { getPlayerPositions } from '../../utils/position-information';
import { PreferredPositions } from './preferred-positions';
import styles from './position-information.module.scss';

type Props = {
  player: IPlayer;
};

export function PositionInformation({ player }: Props) {
  const [editMode, setEditMode] = useState(false);

  const { analytics, events } = useAnalytics();

  const { t } = useTranslation();

  function handleEdit() {
    setEditMode(true);

    analytics?.track(events.POSITIONS_EDIT_CLICKED);
  }

  const playerPositions = useMemo(() => getPlayerPositions(player), [player]);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.description}>
            <Text
              size="h1"
              weight="bold"
              type="h2"
              className={styles.title}
            >
              {t('player_position.heading')}
            </Text>
            <div>
              <Text
                size="h5"
                weight="medium"
                type="h5"
              >
                { t(`entity.position.${player?.position}`, { defaultValue: '' }) }
                { player?.playstyle && ' - ' }
                { t(`entity.playstyle.${player?.playstyle}.title`, { defaultValue: '' }) }
              </Text>
              <Text
                className={styles.playstyle}
                size="body"
                weight="medium"
                type="div"
              >
                { t(`entity.playstyle.${player?.playstyle}.description`, { defaultValue: '' }) }
              </Text>
            </div>
            <PlayerProtected predicate={(ap) => ap.id === player.id}>
              <Button
                className={styles['edit-button']}
                size="md"
                iconEnd="edit-2"
                label={t('player_position.edit_button_label')}
                variant="outlined"
                onClick={handleEdit}
              />
            </PlayerProtected>
          </div>
          <div className={styles.additional}>
            <PreferredPositions className={styles.background} positions={playerPositions} />
          </div>
          <div className={styles.pitch}>
            <SoccerPitch>
              <PitchPositions position={playerPositions} />
            </SoccerPitch>
          </div>
        </div>
      </div>
      <PlayerProtected predicate={(ap) => ap.id === player.id}>
        <PositionInformationModal
          open={editMode}
          player={player}
          onClose={() => setEditMode(false)}
        />
      </PlayerProtected>
    </>
  );
}

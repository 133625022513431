/* eslint-disable max-len */
import {
  useEffect, useRef, useState, cloneElement, Children, ReactElement,
} from 'react';
import { throttle } from 'lodash-es';
import cn from 'classnames';
import styles from './soccer-pitch.module.scss';

type Props = {
  children: ReactElement;
  className?: string;
};

export function SoccerPitch({ children, className }: Props) {
  const [dimension, setDimension] = useState({ width: 0, height: 0 });
  const pitchRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function trackSize() {
      if (!pitchRef.current) {
        setDimension({ width: 0, height: 0 });

        return;
      }

      const { height, width } = pitchRef.current.getBoundingClientRect();
      setDimension({ width, height });
    }

    const throttledResize = throttle(trackSize, 100);

    if (pitchRef.current) {
      trackSize();

      window.addEventListener('resize', throttledResize);
    }

    return () => {
      window.removeEventListener('resize', throttledResize);
    };
  }, []);

  const pitchClasses = cn(styles.pitch, className);

  return (
    <div className={pitchClasses} ref={pitchRef}>
      <svg viewBox="0 0 250 344" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M0.973633 324.904C0.973635 333.741 8.13708 340.904 16.9736 340.904L232.992 340.904C241.839 340.904 249.007 333.724 248.991 324.876L248.461 19.4693C248.445 10.657 241.307 3.51604 232.495 3.49715L17.0079 3.03528C8.15793 3.01631 0.973568 10.1853 0.97357 19.0352L0.973633 324.904Z" stroke="var(--wds-on-surface-outline)" />
        <path d="M202.519 3.39652L202.455 49.8637C202.443 58.6917 195.283 65.8418 186.455 65.8418L65.8752 65.8418C57.0387 65.8418 49.8752 58.6784 49.8752 49.8418L49.8752 3.68648" stroke="var(--wds-on-surface-outline)" />
        <path d="M110.812 3.51758L141.34 3.51758" stroke="var(--wds-on-surface-outline)" strokeWidth="6" strokeLinecap="round" />
        <path d="M149.301 65.9377C139.301 88.1364 112.642 87.2489 102.795 65.843" stroke="var(--wds-on-surface-outline)" />
        <path d="M49.8682 341L49.9318 294.533C49.9439 285.705 57.1038 278.555 65.9318 278.555L186.512 278.555C195.348 278.555 202.512 285.718 202.512 294.555L202.512 340.71" stroke="var(--wds-on-surface-outline)" />
        <path d="M141.575 340.879L111.046 340.879" stroke="var(--wds-on-surface-primary)" strokeWidth="6" strokeLinecap="round" />
        <path d="M103.086 278.461C113.086 256.262 139.745 257.15 149.592 278.555" stroke="var(--wds-on-surface-outline)" />
        <circle cx="125.109" cy="172.58" r="46.1207" stroke="var(--wds-on-surface-outline)" />
        <line x1="0.977356" y1="172.08" x2="249.232" y2="172.08" stroke="var(--wds-on-surface-outline)" />
      </svg>
      <div className={styles.overlay}>
        { Children.map(children, (child: ReactElement) => cloneElement(child, { pitch: dimension })) }
      </div>
    </div>
  );
}
